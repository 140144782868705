import React, { useEffect, useState } from "react";
import { useUserMora } from "../context/userMoraContext";
import { NavLink } from "react-router-dom";
import "../styles/navbar.css";
const Navbar = () => {
  const logOut = () => {
    localStorage.clear();
  };
  const user = localStorage.getItem("token");
  const [priceUrl, setPriceUrl] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const { isStateMora } = useUserMora();

  useEffect(() => {
    setPriceUrl(localStorage.getItem("price"));
  }, []);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  return (
    <header className="container ">
      <nav className="navbar navbar-expand-lg fixed-top padding-container">
        <a className="navbar-brand" href="https://grupoalianzacolombia.com">
          <img
            src="/images/logos/logo.png"
            alt="Grupo Alianza Colombia Viajar"
            width={200}
          />
        </a>
        {showOffcanvas && (
          <div
            className="offcanvas-backdrop show"
            onClick={toggleOffcanvas}
          ></div>
        )}
        {user != null && (
          <>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleOffcanvas}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`offcanvas offcanvas-end ${
                showOffcanvas ? "show" : ""
              }`}
              tabIndex="-1"
              style={{
                height: "900px",
              }}
            >
              <div className="offcanvas-header">
                <a
                  className="navbar-brand"
                  href="https://grupoalianzacolombia.com"
                >
                  <img
                    src="/images/logos/logo.png"
                    alt="Grupo Alianza Colombia Viajar"
                    width={200}
                  />
                </a>
                <button
                  type="button"
                  className="btn-close"
                  onClick={toggleOffcanvas}
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink className="option-nav" to="/reserva">
                      Inicio
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <button
                      className="option-nav"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      reservar
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#000"
                          fillRule="evenodd"
                          d="M12.707 14.707a1 1 0 01-1.414 0l-5-5a1 1 0 011.414-1.414L12 12.586l4.293-4.293a1 1 0 111.414 1.414l-5 5z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <NavLink
                          className="text-white text-uppercase p-2"
                          to="/NochesyConvenios"
                        >
                          Noches y Convenios
                        </NavLink>
                      </li>
                      <hr className="dropdown-divider" />

                      {isStateMora === "1" ? (
                        <li>
                          <NavLink className="text-white text-uppercase p-2">
                            Alianza Priority
                          </NavLink>
                        </li>
                      ) : (
                        <li>
                          <a
                            href={isStateMora === "1" ? "#" : priceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-white text-uppercase p-2"
                            to="/reserva"
                          >
                            Alianza Priority
                          </a>
                        </li>
                      )}
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink className="option-nav" to="/alianza-plus">
                      Alianza Plus
                    </NavLink>
                  </li>
                  <li
                    className={
                      dropdown
                        ? "nav-item dropdown active-dropdown"
                        : "nav-item dropdown"
                    }
                    onClick={() => setDropdown(!dropdown)}
                  >
                    <div
                      className="nav-link option-nav"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      id="dropdown"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="800"
                        height="800"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-7-3a3 3 0 11-6 0 3 3 0 016 0zm-3 11.5a8.46 8.46 0 004.807-1.489c.604-.415.862-1.205.51-1.848C16.59 15.83 15.09 15 12 15c-3.09 0-4.59.83-5.318 2.163-.351.643-.093 1.433.511 1.848A8.46 8.46 0 0012 20.5z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Mi cuenta
                    </div>
                    <div>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <NavLink
                            className="dropdown-item"
                            to="/pagos"
                            id="pagos"
                            style={{ justifyContent: "flex-start" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              viewBox="0 0 511.999 511.999"
                              xmlSpace="preserve"
                            >
                              <g>
                                <path d="M282.701 417.258a6.083 6.083 0 00-6.776-4.445C170.016 428.644 73.038 346.171 73.038 237.85c0-97.522 79.341-176.863 176.863-176.863 119.226 0 205.029 116.522 168.471 230.64-1.066 3.326.839 6.868 4.213 7.772l40.109 10.746a41.828 41.828 0 015.994 2.108c3.338 1.466 7.227-.243 8.311-3.724C524.864 154.762 409.031 0 249.901 0 118.75 0 12.051 106.7 12.051 237.85c0 151.181 137.666 259.523 278.547 234.509 3.525-.626 5.762-4.139 4.836-7.597l-12.733-47.504z"></path>
                                <path d="M250.711 174.075c14.56 0 22.086 9.975 21.931 19.667-.165 10.324 8.224 18.729 18.54 18.593 10.408-.137 18.277-9.225 18.034-19.631-.588-25.217-17.769-46.391-41.02-53.038v-8.094c0-9.852-7.571-18.328-17.412-18.791-10.507-.495-19.18 7.877-19.18 18.275v9.59c-24.297 8.159-41.532 30.828-41.025 57.321.614 32.082 27.225 58.182 59.321 58.182 9.121 0 17.085 5.499 20.688 13.351 1.415 3.082 5.195 4.295 8.213 2.748a42.533 42.533 0 0121.133-4.623c4.337.18 7.397-4.196 5.95-8.289-8.133-23.006-30.157-39.656-55.701-39.78-10.123-.049-19.267-6.71-22.112-16.425-4.418-15.098 7.832-29.056 22.64-29.056zM263.83 356.481c1.258-1.482 1.638-3.521 1.136-5.399-8.469-31.599-9.829-34.283-9.344-43.045.179-3.251-2.239-6.412-6.531-6.412-14.56 0-22.086-9.976-21.931-19.667.165-10.324-8.224-18.729-18.54-18.593-10.408.138-18.277 9.225-18.034 19.631.588 25.218 17.769 46.391 41.02 53.038v8.106c-.001 17.47 21.196 25.333 32.224 12.341zM494.59 436.295l-50.98-50.993 20.976-20.983c9.937-9.943 5.389-26.967-8.206-30.608l-153.417-41.105c-13.578-3.636-26.052 8.813-22.408 22.409l40.431 150.849c4.962 18.551 22.397 19.672 31.296 10.77l20.977-20.977 50.984 50.983c7.145 7.146 18.73 7.144 25.874 0l44.472-44.472c7.145-7.144 7.147-18.726.001-25.873z"></path>
                              </g>
                            </svg>
                            Pagos
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="/"
                            className="dropdown-item"
                            onClick={() => logOut()}
                            style={{ justifyContent: "flex-start" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 -0.5 25 25"
                            >
                              <path d="M11.75 9.874a.75.75 0 001.5 0h-1.5zM13.25 4a.75.75 0 00-1.5 0h1.5zM9.81 6.662a.75.75 0 00-.62-1.366l.62 1.366zM5.5 12.16l-.75-.004v.013l.75-.009zm7 6.84l.009-.75h-.018l.009.75zm7-6.84l.75.009v-.013l-.75.004zm-3.69-6.864a.75.75 0 10-.62 1.366l.62-1.366zm-2.56 4.578V4h-1.5v5.874h1.5zM9.19 5.296a7.581 7.581 0 00-4.44 6.86l1.5.008a6.081 6.081 0 013.56-5.502l-.62-1.366zM4.75 12.17a7.671 7.671 0 007.759 7.581l-.018-1.5a6.17 6.17 0 01-6.241-6.099l-1.5.018zm7.741 7.581a7.67 7.67 0 007.759-7.581l-1.5-.018a6.171 6.171 0 01-6.241 6.099l-.018 1.5zm7.759-7.594a7.581 7.581 0 00-4.44-6.86l-.62 1.366a6.081 6.081 0 013.56 5.502l1.5-.008z"></path>
                            </svg>
                            Cerrar Sesión
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
